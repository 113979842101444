// get vimeo id number from url
export const getVimeoId = url => {
  const match = /vimeo.*\/(\d+)/i.exec(url);

  if (match) {
    return match[1];
  }
};

// turn text to slug
export const slugify = string => {
  return string.replace(/\s+/g, "-").toLowerCase();
};

// convert time
export const timeConvert = mins => {
  let h = Math.floor(mins / 60);
  let m = mins % 60;
  h = h < 10 ? h : h;
  m = m < 10 ? m : m;
  if (h > 0) {
    if (m === 0) {
      return Number(`${h}`);
    } else {
      return Number(`${h}.${m}`);
    }
  } else {
    return Number(`${m}`);
  }
};

// format time to minutes or hours
export const timeFormat = num => {
  if (num > 59) {
    if (num === 60) {
      return "hr";
    } else {
      return "hrs";
    }
  } else {
    return "mins";
  }
};
